<template>
  <v-app id="inspire">
    <!-- <app-header
      :headerOption="headerOption"
      ref="appheader"
      style="height : 0px"
    ></app-header> -->
    <div class="app-context">
      <v-app-bar
        absolute
        color="#fcb69f"
        dark
        shrink-on-scroll
        src="https://picsum.photos/1920/1080?random"
      >
        <template v-slot:img="{ props }">
          <v-img
            v-bind="props"
            gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>

        <div class="logo" style="margin : auto; width : 1000px">
          <v-row>
            <v-col cols="12" md="2"
              ><img class="header_logo" src="@/assets/images/logo_default.svg"
            /></v-col>
            <v-col cols="12" md="6"
              ><h1 style="font-size : 32px">Designer</h1></v-col
            >
          </v-row>
        </div>

        <v-spacer></v-spacer>
      </v-app-bar>
      <div class="designer" v-if="this.$route.params.code === '0'">
        <div class="contents">
          <design-home />
        </div>
      </div>
      <div class="designer" v-if="this.$route.params.code === '1'">
        <div class="contents">
          <design-order-list />
        </div>
      </div>
      <!--<div class="designer" v-if="this.$route.params.code === '2'">
        <div class="contents">
          <design-note />
        </div>
      </div>
      <div class="designer" v-if="this.$route.params.code === '20'">
        <div class="contents">
          <design-note-dtl />
        </div>
      </div>-->
      <div class="designer" v-if="this.$route.params.code === '2'">
        <div class="contents">
          <design-note-test />
        </div>
      </div>
      <app-footer></app-footer>
    </div>
  </v-app>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import DesignHome from "./DesignHome.vue";
import DesignOrderList from "./DesignOrderList.vue";
//import DesignNote from "./DesignNote.vue";
//import DesignNoteDtl from "./DesignNoteDtl.vue";
import DesignNoteTest from "./DesignNoteTest.vue";
import AppHeader from "../../components/AppHeader.vue";
import AppFooter from "@/components/AppFooter";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  components: {
    SideMenu,
    DesignHome,
    DesignOrderList,
    //DesignNote,
    //DesignNoteDtl,
    DesignNoteTest,
    AppFooter,
    AppHeader,
  },
  data() {
    return {
      sideSet: {
        menuName: "designer",
        sideTitle: "Designer",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      headerOption: {
        navOrder: 0,
        isMain: true,
      },
      popupSet: {},
    };
  },
  created() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
    // console.log(this.$route.params.code);
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
.picker1 {
  position: absolute;
}
.designer {
  margin-top : 50px;
}
</style>
