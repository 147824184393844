<template>
  <v-app id="inspire">
    <div class="app-context">
      <v-sheet class="overflow-y-auto">
        <v-container>
          <div class="context flex withSide" style="margin-top: 130px">
            <side-menu :sideSet="sideSet"></side-menu>
            <div style="margin-left: 100px">
              <v-container class="fill-height pa-0" style="top: 0px">
                <v-row class="no-gutters elevation-4">
                  <v-col cols="12" md="12" class="flex-grow-1 flex-shrink-0">
                    <v-responsive
                      v-if="activeChat"
                      class="overflow-y-hidden"
                      height="1000"
                      width="900"
                    >
                      <v-card flat class="d-flex flex-column fill-height">
                        <v-card-title class="no-gutters elevation-4">
                          회원 아이디 : {{ aplyDrId }}
                        </v-card-title>
                        <v-card-text
                          class="flex-grow-1 overflow-y-auto noteScroll scroll type1"
                          style="margin-top: 15px"
                          id="noteScroll"
                        >
                          <template v-for="(msg, i) in messages">
                            <div
                              :key="i + 'r'"
                              :class="{
                                'd-flex flex-row-reverse':
                                  msg.transDrId === 'drdesign',
                              }"
                            >
                              <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                  <v-chip
                                    :color="
                                      msg.transDrId === 'drdesign'
                                        ? ''
                                        : 'primary'
                                    "
                                    dark
                                    style="
                                      height: auto;
                                      white-space: normal;
                                      width: 400px;
                                    "
                                    class="pa-4 mb-2"
                                    v-on="on"
                                  >
                                    <v-row style="min-width: 400px">
                                      <v-col
                                        cols="12"
                                        md="12"
                                        style="
                                          font-size: 11px;
                                          margin: -10px 0 -10px 0;
                                        "
                                      >
                                        {{ msg.title }}
                                      </v-col>
                                      <v-col cols="12" md="12">
                                        <p
                                          v-html="computedLine(msg.conts)"
                                          style="
                                            word-break: break-all;
                                            margin-bottom: -20px;
                                          "
                                        ></p>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="12"
                                        style="height: 10px"
                                      >
                                        <div
                                          style="border-top: 1px solid #fff"
                                        ></div>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                        v-if="
                                          msg.attchId !== null &&
                                          msg.attchId !== ''
                                        "
                                        style="height: 10px"
                                        @click="fileDownload(msg)"
                                      >
                                        <p
                                          style="
                                            font-size: 5px;
                                            margin-top: -15px;
                                          "
                                        >
                                          첨부파일
                                        </p>
                                      </v-col>
                                      <v-col
                                        cols="12"
                                        md="6"
                                        style="height: 10px"
                                      >
                                        <p
                                          style="
                                            font-size: 5px;
                                            margin-top: -15px;
                                          "
                                        >
                                          {{ timeFormat(msg.creDt) }}
                                        </p>
                                      </v-col>
                                    </v-row>
                                  </v-chip>
                                </template>
                              </v-menu>
                            </div>
                          </template>
                        </v-card-text>
                        <v-card-text class="flex-shrink-1">
                          <v-row>
                            <v-col cols="12" md="6">
                              <v-text-field
                                v-model="noteTitle"
                                label="쪽지제목 입력"
                                type="text"
                                no-details
                                outlined
                                hide-details
                                height="10"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="auto">
                              <input
                                id="fileInput"
                                type="file"
                                @change="onFileChange($event)"
                                style="top: 25%; position: relative"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" md="10">
                              <v-textarea
                                v-model="noteContents"
                                label="쪽지내용 입력"
                                type="text"
                                clearable
                                no-resize
                                solo
                                style="margin-bottom: -20px"
                              >
                              </v-textarea>
                            </v-col>
                            <v-col cols="auto">
                              <v-btn
                                x-large
                                outlined
                                color="indigo"
                                @click="saveNote()"
                              >
                                전송
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-responsive>
                  </v-col>
                </v-row>
              </v-container>
            </div>
            <button id="focusControl"></button>
          </div>
        </v-container>
      </v-sheet>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import DefaultPopup from "@/components/modal/DefaultPopup";
export default {
  components: {
    SideMenu,
    DefaultPopup,
  },
  data() {
    return {
      sideSet: {
        menuName: "designer",
        sideTitle: "Designer",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      popupSet: {},
      activeChat: 1,
      messages: [],
      noteTitle: "",
      noteContents: "",
      noteAttachFile: null,
      aplyDrId: "",
    };
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("note", ["GET_NOTE_LIST"]),
  },
  mounted() {
    window.addEventListener("load", (event) => {
      document.querySelector("#noteScroll").scrollTop =
        document.querySelector("#noteScroll").scrollHeight;
    });
    //document.querySelector(".designNoteTest").classList.add("active");
    //console.log(localStorage.getItem("aplyDrId"));
    if (
      localStorage.getItem("aplyDrId") !== null ||
      localStorage.getItem("aplyDrId") !== "" ||
      localStorage.getItem("aplyDrId") !== undefined
    ) {
      this.aplyDrId = localStorage.getItem("aplyDrId");
    }
    this.reload();
  },
  methods: {
    ...mapActions("note", [
      "BRING_NOTE_LIST",
      "FILE_DOWNLOAD",
      "SUBMIT_MESSAGE",
    ]),
    ...mapMutations("basic", ["SET_POPUP"]),
    computedLine(text) {
      if (text !== null) {
        if (text.indexOf("\n") !== -1) {
          return text.split("\n").join("<br />");
        } else {
          return text;
        }
      }
    },
    async reload() {
      this.noteTitle = "";
      this.noteContents = "";
      this.noteAttachFile = null;
      document.querySelector("#fileInput").value = "";

      const NoteInqParam = {
        drId: this.aplyDrId,
      };
      try {
        await this.BRING_NOTE_LIST(NoteInqParam).then((data) => {
          this.messages = this.GET_NOTE_LIST.data;
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
    },
    timeFormat(date) {
      this.$moment.locale("ko");
      return this.$moment(date).format("YY-MM-DD h:mm:ss a");
    },
    async fileDownload(data) {
      const filePath = {
        datePath: data.creDt.substring(0, 10),
        fileId: data.realFileNm,
      };

      //.log("패스 제작 : " + filePath.datePath + "/" + filePath.fileId);
      await this.FILE_DOWNLOAD(filePath).then((response) => {
        //console.log(response);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(response);
        link.download = "다운로드_" + new Date().getTime();
        link.click();
      });
    },
    onFileChange(event) {
      //console.log(event.target.files);
      this.noteAttachFile = event.target.files;
    },
    async saveNote() {
      if (
        this.noteTitle === null ||
        this.noteTitle === "" ||
        this.noteContents === null ||
        this.noteContents === ""
      ) {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content = "쪽지에 전송할 내용을 모두 입력해주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else {
        try {
          const message = {
            recptDrId: this.aplyDrId,
            transDrId: localStorage.getItem("drId"),
            title: this.noteTitle,
            conts: this.noteContents,
          };
          const transedPrParam = JSON.stringify(message);
          const transData = new FormData();
          transData.append("noteParam", transedPrParam);

          if (this.noteAttachFile !== null) {
            //console.log("파일 첨부 여부 : ", this.noteAttachFile[0]);
            transData.append("attachFile", this.noteAttachFile[0]);
          }
          await this.SUBMIT_MESSAGE(transData).then(() => {
            this.SET_POPUP(true);
            this.popupSet.title = "쪽지 발송 완료";
            this.popupSet.content = "쪽지를 성공적으로 발송 하였습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
            this.reload();
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "쪽지 발송 실패";
            this.popupSet.content = "쪽지를 발송 할 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
input,
textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
#app .context.withSide {
  max-width: 100%;
  margin-left: 100px;
}

.scroll {
  padding: 0px 13px 10px 13px !important;
  overflow-y: scroll;
  box-sizing: border-box;
  color: #146f83;
  font-family: "Nanum Gothic";
  margin-right: 50px;
}

/* 스크롤바 설정*/
.type1::-webkit-scrollbar {
  width: 12px;
}

/* 스크롤바 막대 설정*/
.type1::-webkit-scrollbar-thumb {
  height: 17%;
  background-color: #146f83;
  /* 스크롤바 둥글게 설정    */
  border-radius: 10px;
}
</style>
