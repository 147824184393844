<template>
  <v-app id="inspire">
    <div class="app-context">
      <v-sheet class="overflow-y-auto">
        <v-container>
          <div class="context flex withSide" style="margin-top : 130px">
            <side-menu :sideSet="sideSet"></side-menu>
            <div style="margin-left : 100px">
              <img
                class="header_logo"
                src="@/assets/images/user_guide/design_explain.png"
              />
            </div>
            <button id="focusControl"></button>
          </div>
        </v-container>
      </v-sheet>
      <default-popup v-if="checkPopup" :popupSet="popupSet" />
    </div>
  </v-app>
</template>

<script>
import SideMenu from "@/components/sidemenu/SideMenu";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  components: {
    SideMenu,
    DefaultPopup,
  },
  data() {
    return {
      sideSet: {
        menuName: "designer",
        sideTitle: "Designer",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      popupSet: {},
    };
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
  },
  created() {
    window.scrollTo(0, 0);
  },
  mounted() {
    document.querySelector(".designMain").classList.add("active");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
#app .context.withSide {
  max-width: 100%;
  margin-left: 100px;
}
</style>
