var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('div',{staticClass:"app-context"},[_c('v-sheet',{staticClass:"overflow-y-auto"},[_c('v-container',[_c('div',{staticClass:"context flex withSide",staticStyle:{"margin-top":"130px"}},[_c('side-menu',{attrs:{"sideSet":_vm.sideSet}}),_c('div',{staticStyle:{"margin-left":"100px"}},[_c('v-container',{staticClass:"fill-height pa-0",staticStyle:{"top":"0px"}},[_c('v-row',{staticClass:"no-gutters elevation-4"},[_c('v-col',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"cols":"12","md":"12"}},[(_vm.activeChat)?_c('v-responsive',{staticClass:"overflow-y-hidden",attrs:{"height":"1000","width":"900"}},[_c('v-card',{staticClass:"d-flex flex-column fill-height",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"no-gutters elevation-4"},[_vm._v(" 회원 아이디 : "+_vm._s(_vm.aplyDrId)+" ")]),_c('v-card-text',{staticClass:"flex-grow-1 overflow-y-auto noteScroll scroll type1",staticStyle:{"margin-top":"15px"},attrs:{"id":"noteScroll"}},[_vm._l((_vm.messages),function(msg,i){return [_c('div',{key:i + 'r',class:{
                              'd-flex flex-row-reverse':
                                msg.transDrId === 'drdesign',
                            }},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"pa-4 mb-2",staticStyle:{"height":"auto","white-space":"normal","width":"400px"},attrs:{"color":msg.transDrId === 'drdesign'
                                      ? ''
                                      : 'primary',"dark":""}},on),[_c('v-row',{staticStyle:{"min-width":"400px"}},[_c('v-col',{staticStyle:{"font-size":"11px","margin":"-10px 0 -10px 0"},attrs:{"cols":"12","md":"12"}},[_vm._v(" "+_vm._s(msg.title)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('p',{staticStyle:{"word-break":"break-all","margin-bottom":"-20px"},domProps:{"innerHTML":_vm._s(_vm.computedLine(msg.conts))}})]),_c('v-col',{staticStyle:{"height":"10px"},attrs:{"cols":"12","md":"12"}},[_c('div',{staticStyle:{"border-top":"1px solid #fff"}})]),(
                                        msg.attchId !== null &&
                                        msg.attchId !== ''
                                      )?_c('v-col',{staticStyle:{"height":"10px"},attrs:{"cols":"12","md":"6"},on:{"click":function($event){return _vm.fileDownload(msg)}}},[_c('p',{staticStyle:{"font-size":"5px","margin-top":"-15px"}},[_vm._v(" 첨부파일 ")])]):_vm._e(),_c('v-col',{staticStyle:{"height":"10px"},attrs:{"cols":"12","md":"6"}},[_c('p',{staticStyle:{"font-size":"5px","margin-top":"-15px"}},[_vm._v(" "+_vm._s(_vm.timeFormat(msg.creDt))+" ")])])],1)],1)]}}],null,true)})],1)]})],2),_c('v-card-text',{staticClass:"flex-shrink-1"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"쪽지제목 입력","type":"text","no-details":"","outlined":"","hide-details":"","height":"10"},model:{value:(_vm.noteTitle),callback:function ($$v) {_vm.noteTitle=$$v},expression:"noteTitle"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('input',{staticStyle:{"top":"25%","position":"relative"},attrs:{"id":"fileInput","type":"file"},on:{"change":function($event){return _vm.onFileChange($event)}}})])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-textarea',{staticStyle:{"margin-bottom":"-20px"},attrs:{"label":"쪽지내용 입력","type":"text","clearable":"","no-resize":"","solo":""},model:{value:(_vm.noteContents),callback:function ($$v) {_vm.noteContents=$$v},expression:"noteContents"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"x-large":"","outlined":"","color":"indigo"},on:{"click":function($event){return _vm.saveNote()}}},[_vm._v(" 전송 ")])],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('button',{attrs:{"id":"focusControl"}})],1)])],1),(_vm.checkPopup)?_c('default-popup',{attrs:{"popupSet":_vm.popupSet}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }